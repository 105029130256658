import * as React from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 * @framerDisableUnlink
 */

function RotatingGradient(props) {
    const { rotationSpeed, gradientSize } = props

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                borderRadius: "0%",
                overflow: "hidden",
                position: "relative",
            }}
        >
            <motion.div
                style={{
                    position: "absolute",
                    top: `-${gradientSize / 2 - 50}%`,
                    left: `-${gradientSize / 2 - 50}%`,
                    width: `${gradientSize}%`,
                    height: `${gradientSize}%`,
                    backgroundImage: `conic-gradient(transparent 0%, #6F44FF 10%, transparent 0%)`,
                    borderRadius: "0%",
                }}
                animate={{ rotate: 360 }}
                transition={{
                    duration: rotationSpeed,
                    repeat: Infinity,
                    ease: "linear",
                }}
            />
        </div>
    )
}

RotatingGradient.defaultProps = {
    rotationSpeed: 3,
    gradientSize: 200,
}

addPropertyControls(RotatingGradient, {
    rotationSpeed: {
        title: "Rotation Speed",
        type: ControlType.Number,
        defaultValue: RotatingGradient.defaultProps.rotationSpeed,
        min: 0.1,
        max: 20,
        unit: "s",
    },
    gradientSize: {
        title: "Gradient Size",
        type: ControlType.Number,
        defaultValue: RotatingGradient.defaultProps.gradientSize,
        min: 50,
        max: 1000,
        unit: "%",
    },
})

export default RotatingGradient
